<script setup lang="ts">
import { useGeographicExposureChart, DashboardQuery } from '@dashboard'
import { Product } from '@reports'

const companyId = getCurrentDivisionId()
const { $can } = useNuxtApp()

type ShowBy =
  | DashboardQuery.GEOGRAPHIC_EXPOSURE
  | DashboardQuery.GEOGRAPHIC_EXPOSURE_BY_RENT

const showBy = ref<ShowBy>(
  $can('product', Product.LEASE_MANAGER)
    ? DashboardQuery.GEOGRAPHIC_EXPOSURE_BY_RENT
    : DashboardQuery.GEOGRAPHIC_EXPOSURE,
)

const showByText = {
  [DashboardQuery.GEOGRAPHIC_EXPOSURE]: 'area',
  [DashboardQuery.GEOGRAPHIC_EXPOSURE_BY_RENT]: 'rent',
} satisfies Record<ShowBy, string>

/* === FETCH DATA === */
const { data, isPending } = useApiDashboard(companyId, showBy)
const isEmpty = computed(
  () => Object.keys(data.value?.results ?? {}).length === 0,
)
/* === FETCH DATA === */

const options = useGeographicExposureChart(
  computed(() => data.value?.results ?? {}),
  showBy,
  computed(() => data.value?.templateId),
)
</script>

<template>
  <DashboardWidget
    header="Geographic Exposure"
    :subheader="`(by ${showByText[showBy]})`"
    class="pb-0"
    :loading="isPending"
    :is-empty="isEmpty"
    :ui="{
      loading: 'pb-4',
      empty: 'pb-4',
    }"
  >
    <template v-if="$can('product', Product.LEASE_MANAGER)" #secondaryTitle>
      <DashboardToggleRentArea
        v-model="showBy"
        :rent-value="DashboardQuery.GEOGRAPHIC_EXPOSURE_BY_RENT"
        :area-value="DashboardQuery.GEOGRAPHIC_EXPOSURE"
      />
    </template>

    <template #additional>
      <DashboardMissingData
        v-if="data?.missingData"
        v-bind="data.missingData"
      />
    </template>
    <highchart ref="chartRef" :options="options" class="h-64 w-full" />
  </DashboardWidget>
</template>
